import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import FWBanner from '../../components/fullWidthBanner';
import FWTextBanner from '../../components/fullWidthTextBanner';
import FWCard from '../../components/fullWidthCard';


const useStyles = makeStyles((theme) => ({
    root: {
        marginRight: 'auto',
        marginLeft: 'auto',
        marginTop: theme.spacing(5),
    
      },
    promoHeader: {
        fontFamily: ['"Helvetica Neue"', 'sans-serif'],
        fontWeight: 500,
        letterSpacing: '-0.5px',
        fontSize: '4rem',
        lineHeight: '6rem',
        [theme.breakpoints.down('sm')]: {
            fontSize: '2rem',
            lineHeight: '3rem',
        }
    },
    paragraph: {
        marginBottom: theme.spacing(5),
    },
    ullist:{
        fontSize:'1.3rem',
        color:theme.palette.text.secondary,
        [theme.breakpoints.down('sm')]:{
            fontSize:'1rem',
        }
    }
}))

export default function OurValues(props) {
    const classes = useStyles()
    const locale = 'tr-TR'
    return (
        <Layout nodeLocale={locale} location={props.location}>
            <SEO title='ProEbeveyn' lang={locale} location={props.location} />
            <Grid container className={classes.root}>
                <Grid item xs={12} className={classes.praragraph}>
                    <FWBanner imgName='proparent-desktop.jpg' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWTextBanner title='PROPARENT' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWTextBanner bg title='Kendi hayatımız kadar, evcil hayvanımızın hayatından da sorumluyuz.' subtitle='Onların refahını, sağlığını ve mutluluğunu sağlamak çok ama çok önemli. Biz de doğru evcil hayvan ebeveynliğinin yaygınlaştırılması ile dört ayaklı dostlarımızın refahının, sağlığının, mutluğunun sağlanabileceğine inanıyoruz. Kedi ve köpeklerle bir ev paylaşmak, şüphesiz bir ayrıcalık. Bu birlikteliğin her iki taraf için de yararlı ve huzur verici olması çok önemli. Bir canlıyı sahiplenmek, sınırsız sevgi ve mutlulukla beraber, birçok sorumluluk da getirir. ' />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Temel İhtiyaçları' subtitle={<ul className={classes.ullist}><li>Diyetine uygun beslenme ihtiyacını karşılamak</li>
                        <li>Taze içme suyuna erişimini sağlamak</li>
                        <li>Rahat bir yaşam alanı sağlamak </li>
                        <li>Düzenli gezdirmek ve egzersiz yaptırmak </li>
                    </ul>}
                        image='proparent-temel-ihtiyaclar.jpg' bg/>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Sağlık İhtiyaçları' subtitle={<ul className={classes.ullist}><li>Düzenli sağlık kontrolleri</li>
                        <li>Aşı takibi </li>
                        <li>Düzenli iç ve dış parazit uygulamaları
                        </li>
                        <li>Tırnak kesimi, diş temizliği ve tarama gibi bakımlar </li>
                    </ul>}
                        image='proparent-sagliksal-ihtiyaclar-1.jpg' position='right' bg/>
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Eğitim ve Sosyalleşme' subtitle={<ul className={classes.ullist}><li>Basit komutları öğretme</li>
                        <li>Başka hayvanlar ve insanlarla sosyalleştirme
                        </li>

                    </ul>}
                        image='proparent-egitim-sosyallesme-1.jpg' bg />
                </Grid>
                <Grid item xs={12} className={classes.paragraph}>
                    <FWCard title='Çevreye Karşı Sorumluluklar' subtitle={<ul className={classes.ullist}><li>Toplu alanlarda kontrol altında tutmak</li>
                        <li>Dışkılarını toplamak</li>
                        <li>Başka canlılara zarar vermediğinden emin olmak</li>
                    </ul>}
                        image='proparent-cevreye-karsi-sorumluluklar.jpg' position='right' bg/>
                </Grid>
            </Grid>
        </Layout>
    )

}


